<template>
  <div>
    <el-container>
      <el-header height="80px">
        <div class="header-view">
          <h3>郑州航空港区平安建设促进中心智慧管理平台</h3>
          <div class="msg-view">
            <i-icon name="iconfont-icon-shuaxin1" color="#ffffff" size="30px" @click="onRefresh()"></i-icon>
            <i-icon name="iconfont-icon-lingdang" color="#ffffff" size="30px" @click=" msg_show = !msg_show"></i-icon>
            <i class="ioc" v-if="$store.state.notice_list.length"></i>

            <!--弹出的预警-->
            <div class="msg-popup" :class="{'msg-show' : msg_show}" v-if="$store.state.notice_list.length">
              <div class="line">
                <div class="lingdang">
                  <i-icon name="iconfont-icon-lingdang" color="#385FF2" size="20px"></i-icon>
                </div>
                <p class="name">备忘预警</p>
                <span @click.stop="seeReminder()">我知道了</span>
              </div>
              <div class="msg-list">
                <div class="msg-item" v-for="(item,index) in $store.state.notice_list" :key="index">
                  <p class="line-01 title">{{ item.title }}</p>
                  <p class="line-01 content">{{ item.content }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="right" @click.stop="nav_show = !nav_show">
            <span v-if="user">欢迎您,{{ user.nickname }}</span>
            <i-icon name="iconfont-icon-xiajiantou_huaban" color="#B4C9FB" size="22px" @click="nav_show = !nav_show"></i-icon>
          </div>

          <div class="nav-hide" :class="{'nav-show' : nav_show}">
            <div class="nav-list">
              <div class="line" @click="onChangePassword()">
                <i-icon name="iconfont-icon-mima" color="#232323" size="20px" @click="onChangePassword()"></i-icon>
                <span>修改密码</span>
              </div>
              <div class="line" @click.stop="onLoginOut()">
                <i-icon name="iconfont-icon-tuichu" color="#232323" size="20px" @click="onLoginOut()"></i-icon>
                <span>退出登录</span>
              </div>
            </div>
          </div>
        </div>
      </el-header>
      <el-container>
        <div class="el-aside-bg">
          <el-aside width="180px">
            <!-- 左侧菜单-->
            <sidebar v-if="$store.state.routers.length"></sidebar>
          </el-aside>
        </div>

        <el-main style="background: #F3F4FB">
          <keep-alive :include="cachedViews">
            <router-view></router-view>
          </keep-alive>

          <!--          <router-view v-slot="{ Component }">-->
          <!--            <transition name="move" mode="out-in">-->
          <!--              <component :is="Component"/>-->
          <!--            </transition>-->
          <!--          </router-view>-->
        </el-main>
      </el-container>
    </el-container>
    <change-password ref="change_password"></change-password>

      <!--回到顶部-->
    <el-backtop target=".el-main"></el-backtop>
  </div>

</template>

<script>
import changePassword from "@/components/change-password";
import sidebar from "./sidebar";

export default {
  name: "index",
  components: {changePassword, sidebar},
  data() {
    return {
      nav_show: false,
      msg_show: false,
    }
  },
  computed: {
    cachedViews() {
      return this.$store.state.cachedViews
    },
  },
  mounted() {
    //监听全局点击事件
    document.getElementById('app').onclick = () => {
      if (this.nav_show) this.nav_show = false
      if (this.msg_show) this.msg_show = false
    }
  },
  methods: {
    // 当点击退出登录
    onLoginOut() {
      this.$confirm('是否退出登录', '提示').then(() => {
        this.$store.commit('loginout')
      }).catch(() => {
        console.log('取消')
      })
    },
    //  当点击修改密码
    onChangePassword() {
      this.$refs.change_password.show()
    },
    //打开详情
    openDetail(item) {
      // this.seeReminder(item)  //先点击查看
      // this.msg_show = false
    },
    //点击我知道了
    seeReminder() {
      const list = this.$store.state.notice_list
      const ids = list.map((e) => {
        return e.id
      })
      const params = {
        ids: ids.join(',')
      }
      this.$api.post('/api/manager/see_reminder', params).then((res) => {
        this.getNoticeMsg()
      }).catch((err) => {
        this.apiErr(err)
      })
    },
    // windown刷新页面
    onRefresh() {
      window.location.reload(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-header {
  background: #1F3279;

  .header-view {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    user-select: none;

    h3 {
      color: #ffffff;
      font-size: 26px;
    }

    .msg-view {
      position: relative;
      margin-left: auto;

      .iconfont {
        margin-left: 20px;
        cursor: pointer;
      }

      .ioc {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: #FD1E3D;
      }

      .msg-popup {
        position: absolute;
        z-index: 99;
        top: 45px;
        right: -20px;
        display: flex;
        flex-direction: column;
        background: #385FF2;
        border-radius: 10px;
        padding: 20px;
        width: 250px;
        box-shadow: 0px 3px 10px 2px rgba(113, 131, 201, 0.58);
        transition: all 100ms;
        opacity: 0;
        transform: translateY(-20px);
        height: 0;
        overflow: hidden;

        .line {
          display: flex;
          align-items: center;

          .lingdang {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ffffff;
            border-radius: 100px;
            padding: 2px;
            margin-right: 10px;

          }

          p {
            color: #ffffff;
            font-weight: bold;
            font-size: 16px;
          }

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #FFFFFF;
            border-radius: 4px;
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            color: #385FF2;
            padding: 0 10px;
            margin-left: auto;
            cursor: pointer;
          }
        }

        .msg-list {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          transition: height 100ms;
        }

        .msg-item {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          color: #ffffff;
          margin-top: 12px;

          .title {
            font-size: 18px;
            font-weight: bold;
          }

          .content {
            font-size: 14px;
            margin-top: 6px;
            opacity: .8;
          }


        }


      }

      .msg-show {
        opacity: 1;
        transform: translateY(0);
        height: auto;
        overflow: auto;
      }
    }

    .right {
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;
      margin-left: 30px;

      span {
        font-size: 18px;
        color: #B4C9FB;
        padding-right: 12px;
      }
    }

    .nav-hide {
      position: absolute;
      z-index: 99;
      right: 20px;
      top: 70px;
      background: #ffffff;
      border-radius: 10px;
      -moz-box-shadow: 0 2px 5px #E0E0E0; /* 老的 Firefox */
      box-shadow: 0 2px 5px #E0E0E0;
      overflow: hidden;
      transition: all 100ms;
      height: 0;
      opacity: 0;
      transform: translateY(-20px);

      .nav-list {
        width: 160px;
        padding: 10px 20px;
      }

      .line {
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          padding-left: 10px;
          font-size: 16px;
          color: #232323;
          line-height: 50px;
        }

      }
    }

    .nav-show {
      opacity: 1;
      height: 120px;
      transform: translateY(0);
    }
  }
}

.el-aside-bg {
  background: #1F3279;
  height: calc(100vh - 80px);
}

.el-aside {
  background: #1F3279;
  height: calc(100vh - 120px);
}


.el-container {
  height: 100vh;
  overflow-y: hidden;
}

.el-main {
  overflow: overlay;
  position: relative;
}

.el-main::-webkit-scrollbar {
  position: absolute;
  /*滚动条整体样式*/
  width: 12px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
  background: rgb(255 255 255 / 50%);

}

.el-main::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: rgb(0 0 0 / 40%);
}

</style>


