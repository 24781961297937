/**
 * @Author: yangzhenhua
 * @Date:   2023-03-25 20:38:14
 * @Last Modified by:   yangzhenhua
 * @Last Modified time: 2023-04-03 09:57:08
 */
import axios from "axios";

/**
 * 16进制转字符串
 *    @description 16进制转字符串
 *    @param {String} str 16进制文件流
 *    @return {String} 字符串
 */
export function HexToString(str) {
    if (str.length % 2 != 0) {
        alert('必须为偶数');
        return ''
    }
    let a;
    let string = [];
    for (var i = 0; i < str.length; i = i + 2) {
        a = parseInt(str.substr(i, 2), 16);
        string.push(String.fromCharCode(a));
    }
    return string.join('');
}


/**
 * 字符串转16进制
 * @param {String} str  -传入要转换的字符串
 * @return {String} -返回转换后的16进制字符串
 */
export function StringToHex(str) {
    if (str === "")
        return "";
    var hexCharCode = [];
    hexCharCode.push("0x");
    for (var i = 0; i < str.length; i++) {
        hexCharCode.push((str.charCodeAt(i)).toString(16));
    }
    return hexCharCode.join("");
}

/**
 * 时间戳转化为年 月 日 时 分 秒
 * @param {Number,Stying} number - 传入时间戳或时间字符串
 * @param {String} format：返回格式，支持自定义，但参数必须与formateArr里保持一致
 * @return {String} 返回格式化之后的字符串
 * @example
 * formatTime('2020/08/27 11:01:22','Y/M/D h:m:s')
 * formatTime('2020/08/27 11:01:22','Y/M/D h:m:s')
 * formatTime('2020-08-27 11:01:22','Y/M/D h:m:s')
 * formatTime('1598497282','Y/M/D h:m:s')
 * formatTime('1598497282000','Y/M/D h:m:s')
 */
export function formatTime(number, format = 'Y-M-D h:m:s') {
    let Timestamp = ''
    let number_str = ""

    if (number) {
        number_str = number.toString() //先转成字符串
    } else {
        number_str = "1900-01-01 00:00:00"
    }


    if (number_str.length == 13) {
        // 如果是13位时间戳
        Timestamp = parseInt(number_str)

    } else if (number_str.length == 10) {
        // 如果是10位时间戳

        Timestamp = (number_str * 1000)

    } else {

        // 如果是时间字符串
        if (number_str.indexOf("-") != -1) {
            Timestamp = number_str.replace(/-/g, "/")
        } else {
            Timestamp = number_str;
        }
    }


    var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
    var returnArr = [];

    var date = new Date(Timestamp);
    returnArr.push(date.getFullYear());
    returnArr.push(formatNumber(date.getMonth() + 1));
    returnArr.push(formatNumber(date.getDate()));
    returnArr.push(formatNumber(date.getHours()));
    returnArr.push(formatNumber(date.getMinutes()));
    returnArr.push(formatNumber(date.getSeconds()));

    for (var i in returnArr) {
        format = format.replace(formateArr[i], returnArr[i]);
    }
    return format;
}

/**
 *  数据转化-如果字符串或数字不足两位，手动在前面加上0返回两位的字符串
 * @param {String,Number} n -传入的字符串或数字
 * @return {String,Number} --手动在前面加上0返回两位的字符串
 */
function formatNumber(n) {
    n = n.toString()
    return n[1] ? n : '0' + n
}


/**
 * 隐藏中间部分
 * @param {String,Number} str -原字符串
 * @param {Number}   frontLen -前面显示几位
 * @param {Number}  endLen -后面显示几位
 * @example
 * plusXing('18888888888',3,4)    //188****8888
 */

export function plusXing(str, frontLen = 3, endLen = 4) {
    if (!str) return ""
    var len = str.length - frontLen - endLen;
    var xing = '';
    for (var i = 0; i < len; i++) {
        xing += '*';
    }
    if (str.length === 1) {
        return str + '*';
    } else if (str.length == 2) {
        return str.substring(0, frontLen) + '*' + str.substring(str.length - endLen);
    } else {
        return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
    }
}


/**
 * 时间对比
 * @param {String} startTime -开始时间
 * @param {String} endTime -结束时间
 * @example  little('2020/11/11 11:42:00','2020/11/11 11:42:00')    //false
 */
export function little(startTime, endTime) {
    let start_time = startTime.replace(/-/g, "/")
    let end_time = endTime.replace(/-/g, "/")
    let start = new Date(Date.parse(start_time));
    let end = new Date(Date.parse(end_time));
    //进行比较
    return start > end
}

/**
 * 传入秒数，转化成格式化的时间，用于倒计时
 * @param {String,Number} time -剩余的秒数
 * @return {String} 返回格式化之后的剩余时间
 */
export function toTime(time) {
    let timeStr = '';
    let stringFormat = (i) => {
        return i < 10 ? `0${i}` : `${i}`;
    }
    let minuteTime = 0;
    let secondTime = 0;
    let hourTime = 0;
    if (time < 60) {
        timeStr = `00:${stringFormat(time)}`
    } else if (time >= 60 && time < 3600) {
        minuteTime = parseInt(time / 60);
        secondTime = parseInt(time % 60);
        timeStr = `${stringFormat(minuteTime)}:${stringFormat(secondTime)}`;
    } else if (time >= 3600) {
        let _t = parseInt(time % 3600);
        hourTime = parseInt(time / 3600);
        minuteTime = parseInt(_t / 60);
        secondTime = parseInt(_t % 60);
        timeStr = `${stringFormat(hourTime)}:${stringFormat(minuteTime)}:${stringFormat(secondTime)}`
    }
    return timeStr;
}


/**
 * 时间间隔的天数
 * @param {String} startDate 开始时间 2021-08-10 14:00:00
 * @param {String} endDate 结束时间 不传默认取当前时间
 * @return {String} 返回字符串结果  "昨天 14:00"、"今天 14:00"、"2天前"
 * */
export function getDateDiff(startDate, endDate = null) {
    let start, end;

    //ios时间兼容处理
    if (startDate) {
        start = startDate.replace(/-/g, "/"); //开始时间
    }
    if (endDate) {
        end = endDate.replace(/-/g, "/"); //结束时间
    }

    var startTime = new Date(Date.parse(start)).getTime(); //开始时间戳
    var endTime = 0;
    if (end) {
        endTime = new Date(Date.parse(end)).getTime(); //结束时间戳
    } else {
        endTime = new Date().getTime(); //结束时戳
    }
    let yesterday = new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000); //昨天的时间对象
    if (new Date(start).toDateString() == new Date().toDateString()) {
        // 今天
        return "今天 " + formatTime(start, "h:m");
    } else if (new Date(start).toDateString() == yesterday.toDateString()) {
        //昨天
        return "昨天 " + formatTime(start, "h:m");
    } else {
        var dates = Math.abs((endTime - startTime)) / (1000 * 60 * 60 * 24);
        return Math.ceil(dates) + "天前"; //向上取整
    }
}


/**
 * 判断是不是微信
 * @return {Boolean}
 */
export function isWeixin() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
}


/**
 * 随机生成固定位数或者一定范围内的字符串数字组合
 * @param {Number} min 范围最小值
 * @param {Number} max 范围最大值，当不传递时表示生成指定位数的组合
 * @returns {String} 返回字符串结果
 * */
export function randomRange(min, max) {
    var returnStr = "",
        range = (max ? Math.round(Math.random() * (max - min)) + min : min),
        arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k',
            'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F',
            'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
        ];

    for (var i = 0; i < range; i++) {
        var index = Math.round(Math.random() * (arr.length - 1));
        returnStr += arr[index];
    }
    return returnStr;
}


/**
 * 获取时间格式  例如： 今天(10月30日)10:00
 * @param {Number || String} t 时间
 * @returns {String} 返回字符串结果 时间描述
 * */


export function getformatTime(t) {

    if (!t) return "--"
    const nowDate = new Date(t.replace(/-/g, '/'))
    const time = nowDate.getTime()
    const todayStamp = new Date(new Date().toLocaleDateString()).getTime(); //今天零点的时间戳
    let month = nowDate.getMonth() + 1
    let day = nowDate.getDate()
    let hour = nowDate.getHours()
    let minute = nowDate.getMinutes()
    if (month < 10) month = "0" + month
    if (day < 10) day = "0" + day
    if (hour < 10) hour = "0" + hour
    if (minute < 10) minute = "0" + minute

    if (time < todayStamp) {
        // 以前的日期
        return `${month}月${day}日 ${hour}:${minute}`

    } else if (time >= todayStamp && time < (todayStamp + 86400000)) {
        //今天
        return `今天(${month}月${day}日)${hour}:${minute}`
    } else if (time >= (todayStamp + 86400000) && time < (todayStamp + 172800000)) {
        //明天
        return `明天(${month}月${day}日)${hour}:${minute}`
    } else if (time >= todayStamp + 172800000 && time < todayStamp + 259200000) {
        //后天
        return `后天(${month}月${day}日)${hour}:${minute}`
    } else {
        return `${month}月${day}日 ${hour}:${minute}`
    }

}

// 获取时间格式  例如： 10月30日(周一)10:00
export function getformatWeek(t) {
    if (!t) return "--"
    const nowDate = new Date(t.replace(/-/g, '/'))
    //   获取周几
    const day = nowDate.getDay();
    const weeks = new Array("周日", "周一", "周二", "周三", "周四", "周五", "周六");
    const week = weeks[day];
    const time = `${formatTime(nowDate, 'M月D日')}(${week})${formatTime(nowDate, 'h:m')}`
    return time
}


/**
 * 文件下载
 * @param url
 * @param fileName
 * @returns {Promise}
 */
//api.js
export function exportExcel(url, fileName) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers['content-type'] = 'application/json;charset=UTF-8'
        axios({
            method: 'get',
            url: url, // 请求地址
            responseType: 'blob' // 表明返回服务器返回的数据类型
        }).then(
            response => {
                let blob = new Blob([response.data], {type: 'application/vnd.ms-excel'})
                let file_name = `${fileName ? fileName : Date.parse(new Date())}.xlsx`
                if (window.navigator.msSaveOrOpenBlob) {
                    navigator.msSaveBlob(blob, file_name)
                } else {
                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = file_name
                    link.click()
                    window.URL.revokeObjectURL(link.href)  //释放内存

                }
                resolve(response.data)
            },
            err => {
                reject(err)
            }
        )
    })
}


/**
 * 按身份证号码获取性别
 * @idNumber 身份证号码
 * @return 男：0；女：1；异常（身份证号码为空或长度、格式错误）：undefined
 */
export function getGenderByIdNumber(idNumber) {
    if (idNumber) {
        let genderCode; // 性别代码
        if (idNumber.length == 18) { // 二代身份证号码长度为18位（第17位为性别代码）
            genderCode = idNumber.charAt(16);
        } else if (idNumber.length == 15) { // 一代身份证号码长度为15位（第15位为性别代码）
            genderCode = idNumber.charAt(14);
        }
        if (genderCode && !isNaN(genderCode)) {
            // 两代身份证号码的性别代码都为男奇女偶
            if (parseInt(genderCode) % 2 == 0) {
                return 1;
            }
            return 0;
        }
    } else {
        return undefined
    }
}


// 分割数组
export function getNewArray(array, subGroupLength) {
    let index = 0;
    let newArray = [];
    while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
    }
    return newArray;
}


export function getQueryVariable(variable) {
    var query = window.location.href.split('?')[1];
    if (query) {
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) {
                return pair[1].split("#")[0];
            }
        }
        return (false);
    } else {
        return false
    }
}
