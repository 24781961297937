const Cookies = {
    get: (key) => {
        return window.localStorage.getItem(key)
    },
    remove: (key) => {
        window.localStorage.removeItem(key)
    },
    set: (key, provider) => {
        window.localStorage.setItem(key, provider)
    }
}
export default Cookies
