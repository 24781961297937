/**
 * @Author: yangzhenhua
 * @Date:   2023-03-25 20:38:14
 * @Last Modified by:   yangzhenhua
 * @Last Modified time: 2023-04-03 09:54:45
 */
import h5Copy from '@/common/copy.js'
import {mapState} from "vuex"
import {formatTime} from "@/utils";

export default {
    data() {
        return {
            request: false,
            loading_show: null
        }
    },
    computed: {
        ...mapState({
            user: 'user_info',
        }),
        getFileType() {
            return function (url) {
                if (!url) {
                    return {
                        name: "",
                        type: ""
                    }
                }
                let arr = url.split('.')

                const type = arr[arr.length - 1]
                arr.pop()
                const name = arr.join(",")
                return {
                    name: name,
                    type: type
                }
            }
        },
        getFileIoc() {
            return function (url) {
                if (!url) {
                    return {
                        name: "",
                        type: ""
                    }
                }
                let arr = url.split('.')

                const type = arr[arr.length - 1]
                switch (type) {
                    case 'png':
                        return require('@/assets/image/file/image.png')
                    case 'jpg':
                        return require('@/assets/image/file/image.png')
                    case 'gif':
                        return require('@/assets/image/file/image.png')
                    case 'jpeg':
                        return require('@/assets/image/file/image.png')
                    case 'mp3':
                        return require('@/assets/image/file/audio.png')
                    case 'wav':
                        return require('@/assets/image/file/audio.png')
                    case 'wmv':
                        return require('@/assets/image/file/audio.png')
                    case 'acc':
                        return require('@/assets/image/file/audio.png')
                    case 'mp4':
                        return require('@/assets/image/file/video.png')
                    case 'mpeg':
                        return require('@/assets/image/file/video.png')
                    case 'txt':
                        return require('@/assets/image/file/txt.png')
                    case 'doc':
                        return require('@/assets/image/file/doc.png')
                    case 'docx':
                        return require('@/assets/image/file/doc.png')
                    case 'pdf':
                        return require('@/assets/image/file/pdf.png')
                    case 'xls':
                        return require('@/assets/image/file/xls.png')
                    case 'xlsx':
                        return require('@/assets/image/file/xls.png')
                    default:
                        return require('@/assets/image/file/weizhi.png')
                }
            }
        },
        //    上传文件api
        uploadURL() {
            return `${this.$config.baseURL}/api/common/upload`
        }
    },
    methods: {
        formatTime,
        getUserInfo(type = null) {
            return new Promise((resolve, reject) => {
                this.$api.get("/api/user/set_personal").then(res => {
                    this.$store.commit("setUserInfo", res.data)
                    resolve(res.data)
                }).catch(err => {
                    this.apiErr(err)
                    reject()
                })
            })
        },
        // 获取右上角备忘预警数据
        getNoticeMsg() {
            this.$api.get("/api/manager/my_reminder").then(res => {
                console.log("我的备忘预警", res.data)
                this.$store.commit('setNoticeList', res.data)

            }).catch(err => {
                this.apiErr(err)
            })
        },
        getSrc(src) {
            if (!src) return ''
            if (src.charAt(0) === '/') return this.$config.baseURL + src //图片在服务器
            return src
        },

        goBack() {
            console.log("返回")
            window.history.back()
        },
        // 复制
        copy(content) {
            const result = h5Copy(content)
            if (result === false) {
                this.fail('复制失败')
            } else {
                this.success('复制成功')
            }
        },

        // 图片预览
        showImg(img, current = 0) {

        },
        openWin(url) {
            this.$router.push(url).then(res => {
            })
                .catch(err => {
                })
        },
        openNewWin(url) {
            window.open('/web/#' + url)
        },
        //打开外部网页
        openWeb(url) {
            console.log(url);
            if (!url) return false;
            window.open(url)
        },
        // 提示框
        toast(text, duration = 1.2) {
            this.$message.info(text, duration);
        },
        // 成功提示框
        success(text, duration = 1) {
            this.$message.success(text, duration);
        },
        // 成功提示框
        successBox(text, duration = 1.5) {
            this.$notify({
                title: '成功',
                message: text,
                type: 'success',
                duration: duration * 1000
            });
        },
        // 错误提示框
        fail(text, duration = 1.2) {
            this.$message.error(text, duration);
        },
        // 显示加载框  //不会自动关闭
        loadingShow(text = '加载中', time = 10) {
            this.loading_show = this.$loading({
                lock: true,
                text: text,
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });

            if (time > 0) {
                setTimeout(() => {
                    this.loading_show.close();
                }, time * 1000);

            }

            // this.$store.commit('setLoading', {active: true, text: text})
            // if (time > 0) {
            //     setTimeout(() => {
            //         this.loadingHide()
            //     }, time)
            // }
        },
        // 关闭加载框
        loadingHide() {
            if (this.loading_show) this.loading_show.close();

            // this.$store.commit('setLoading', {active: false})
        },

        // 接口报错提示
        apiErr(err) {
            this.loadingHide()
            if (err.message) {
                this.fail(err.message)
            } else if (err.msg) {
                this.fail(err.msg)
            } else {
                this.fail('系统错误')
            }
        },
        // 防抖处理
        isRequest() {
            if (this.request) return true;
            this.request = true;
            setTimeout(() => {
                this.request = false;
            }, 2000)
            return false;
        },
        //格式化性别
        formatterGender(e) {
            if (e.gender === 0) {
                return "男"
            } else if (e.gender === 1) {
                return "女"
            } else {
                return "未知"
            }
        },
        getImageSrc(src) {
            if (!src) return ''
            if (src.charAt(0) === '/') return this.$config.baseURL + src
            return src
        },
        downloadBlob(blobUrl, fileName = '', revokeObjectURL) {
            let downloadElement = document.createElement('a')
            downloadElement.href = blobUrl
            //下载后文件名
            downloadElement.download = fileName
            document.body.appendChild(downloadElement)
            //点击下载
            downloadElement.click()
            //下载完成移除元素
            document.body.removeChild(downloadElement)
            if (revokeObjectURL == null || revokeObjectURL) {
                //释放掉blob对象
                window.URL.revokeObjectURL(blobUrl)
            }
        },
        //tabel格式化
        formatterContent(row, column, cellValue, index) {
            return cellValue || '--'
        },
        // 格式化申请人姓名
        formatterApplyName(row) {
            if (row.is_party === 0) {
                //  委托案件
                return `${row.username}(委托案件)`
            }
            if (row.people_type === 2) {
                return `${row.username}(企业)`
            }
            return `${row.username}`

        }

    }
}
