import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import config from "./config"
import api from "./api/index.js"
import base from "./mixins/index.js"


Vue.prototype.$config = config
Vue.prototype.$api = api
Vue.mixin(base)

import ElementUI from 'element-ui';
import './assets/theme/index.css';


import VueWechatTitle from 'vue-wechat-title'

Vue.use(VueWechatTitle)

Vue.use(ElementUI);


//iconfont 图标
import IIcon from './components/i-icon.vue'

Vue.component('i-icon', IIcon)

import IImageDialog from "@/components/i-image-dialog";

Vue.component('i-image-dialog', IImageDialog)

import IVideoDialog from "@/components/i-video-dialog";

Vue.component('i-video-dialog', IVideoDialog)

import IBack from "@/components/i-back";

Vue.component('i-back', IBack)

// import Cookies from "js-cookie";
import Cookies from "@/utils/cookie.js";

Vue.prototype.$cookie = Cookies


// 引入element-tiptap
import {ElementTiptapPlugin} from 'element-tiptap';
// import element-tiptap 样式
import 'element-tiptap/lib/index.css';
//使用
Vue.use(ElementTiptapPlugin, {
    lang: 'zh',
});


if (process.env.NODE_ENV !== 'development') console.log = () => {
} //生产模式


Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
