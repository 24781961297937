<template>
  <el-image-viewer v-if="dialogVisible" :on-close="closeViewer" :url-list="[url]"/>

</template>
<script>
// 导入组件
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: "i-image-dialog",
  components: {ElImageViewer},
  data() {
    return {
      dialogVisible: false,
      url: ""
    }
  },
  methods: {
    show(url) {
      this.url = url
      this.$nextTick(() => {
        this.dialogVisible = true
      })
    },
    closeViewer() {
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped>
.img-view {
  max-height: 60vh;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}
</style>
