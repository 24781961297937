
const config = {
    // baseURL: window.location.origin,
    baseURL: "https://zhfw.daoben.tech",
    // baseURL: "https://zhfwtest.daoben.tech",
}

if (process.env.NODE_ENV === 'development') {
    config.baseURL = "https://zhfw.daoben.tech"
    // config.baseURL = "https://zhfwtest.daoben.tech"
}
export default config
