<template>
  <div>
    <el-dialog title="视频预览" :visible.sync="dialogVisible" @close="onClose()">
      <div class="img-view">
        <video ref="videoPlayer" class="video-js" v-if="dialogVisible"></video>
      </div>
    </el-dialog>
  </div>
</template>
<script>


export default {
  name: "i-image-dialog",
  data() {
    return {
      dialogVisible: false,
      player: null,
    }
  },


  methods: {
    // 实例化播放器
    createVideo(url) {
      let options = {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: url,
          }
        ]
      }
      this.player = videojs(this.$refs.videoPlayer, options)
    },
    show(url) {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.createVideo(url)
      })
    },
    //  关闭弹窗
    onClose() {
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped>
.img-view {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;

}

.video-js {
  width: 100%;
  height: 100%;
}
</style>
