import router from "@/router";
import Layout from "@/views/layout";
import store from "@/store";
import api from "@/api";

//动态获取路由
const dynamicRouters = () => {
    console.log('开始获取动态路由')
    api.get('/api/rule/get_user_menu').then((res) => {
        const routers = res.data.map((e) => {
            e.component = (e.component === 'Layout') ? Layout : loadView(e.component)
            e.children = e.children.map(item => {
                item.component = (item.component === 'Layout') ? Layout : loadView(item.component)
                return item
            })
            return e
        })

        routers.forEach((e) => {
            router.addRoute(e)
        })
        router.addRoute({
            path: '*',
            redirect: '/404'
        })
        console.log('路由菜单', routers)
        store.commit('setMenuList', routers)

    }).catch((err) => {
        console.log('获取路由菜单出错', err)
    })


    return false
    return new Promise((resolve, reject) => {
        //获取动态路由
        const routers = [
            {
                path: '/index',
                name: "index",
                meta: {title: "主页", icon: "ic_menu01"},
                component: () => import("@/views/home/index"),
                alwaysShow: 0,
                children: []
            },
            {
                path: '/add',
                name: 'add',
                alwaysShow: 0,
                meta: {title: "新增案件", icon: "ic_menu01"},
                component: () => import("@/views/layout"),
                children: [
                    {
                        path: "/add",
                        name: "add",
                        meta: {title: '新增案件', login: true},
                        alwaysShow: 0,
                        component: () => import ( "@/views/case/add")
                    },
                ],
            },
            {
                path: '/list_01',
                name: 'list_01',
                alwaysShow: 0,
                component: () => import("@/views/layout"),
                meta: {title: "待受理", icon: "ic_menu01"},
                children: [
                    {
                        path: "/list_01",
                        name: "list_01",
                        meta: {title: '待受理', login: true},
                        alwaysShow: 0,
                        component: () => import ( "@/views/case/list")
                    },
                ],
            },
            {
                path: '/list_02',
                name: 'list_2',
                alwaysShow: 0,
                component: () => import("@/views/layout"),
                meta: {title: "已受理", icon: "ic_menu01"},
                children: [
                    {
                        path: "/list_02",
                        name: "list_2",
                        meta: {title: '已受理', login: true},
                        alwaysShow: 0,
                        component: () => import ( "@/views/case/list")
                    },
                ],
            },
            {
                path: '/list_03',
                name: 'list_3',
                alwaysShow: 0,
                component: () => import("@/views/layout"),
                meta: {title: '已流转', login: true},
                children: [
                    {
                        path: "/list_03",
                        name: "list3",
                        meta: {title: '已流转', login: true},
                        alwaysShow: 0,
                        component: () => import ( "@/views/case/list")
                    },
                ],
            },
            {
                path: '/list_04',
                name: 'list_4',
                alwaysShow: 0,
                component: () => import("@/views/layout"),
                meta: {title: '已办结', login: true},
                children: [
                    {
                        path: "/list_04",
                        name: "list4",
                        meta: {title: '已办结', login: true},
                        alwaysShow: 0,
                        component: () => import ( "@/views/case/list")
                    },
                ],
            },
            {
                path: '/list_05',
                name: 'list_5',
                alwaysShow: 0,
                component: () => import("@/views/layout"),
                meta: {title: '已退回', login: true},
                children: [
                    {
                        path: "/list_05",
                        name: "list5",
                        meta: {title: '已退回', login: true},
                        alwaysShow: 0,
                        component: () => import ( "@/views/case/list")
                    },
                ],
            },
            {
                path: '/detail',
                name: 'detail',
                alwaysShow: 1,
                component: () => import("@/views/layout"),
                meta: {title: '案件详情', login: true},
                children: [
                    {
                        path: "/detail",
                        name: "detail",
                        meta: {title: '案件详情', login: true},
                        alwaysShow: 1,
                        component: () => import ( "@/views/case/detail")
                    },
                ],
            },
            {
                path: '/current-warning',
                name: '/current-warning',
                alwaysShow: 0,
                component: () => import("@/views/layout"),
                meta: {title: '本节预警', login: true},
                children: [
                    {
                        path: "/current-warning",
                        name: "/current-warning",
                        meta: {title: '本节预警', login: true},
                        alwaysShow: 0,
                        component: () => import ( "@/views/case/current-warning")
                    },
                ],
            },
            {
                path: '/case-warning',
                name: '/case-warning',
                alwaysShow: 0,
                component: () => import("@/views/layout"),
                meta: {title: '案件预警', login: true},
                children: [
                    {
                        path: "/case-warning",
                        name: "/case-warning",
                        meta: {title: '案件预警', login: true},
                        alwaysShow: 0,
                        component: () => import ( "@/views/case/case-warning")
                    },
                ],
            },
            {
                path: '/',
                name: '',
                alwaysShow: 0,
                component: () => import("@/views/layout"),
                meta: {title: '权限管理', login: true},
                children: [
                    {
                        path: "/group",
                        name: "group",
                        meta: {title: '单位管理', login: true},
                        alwaysShow: 0,
                        component: () => import ( "@/views/power/group")
                    },
                    {
                        path: "/user",
                        name: "user",
                        meta: {title: '用户管理', login: true},
                        alwaysShow: 0,
                        component: () => import ( "@/views/power/user-list")
                    },
                    {
                        path: "/menu",
                        name: "menu",
                        meta: {title: '菜单管理', login: true},
                        alwaysShow: 0,
                        component: () => import ( "@/views/power/menu-list")
                    },
                    {
                        path: "/user-log",
                        name: "user-log",
                        meta: {title: '用户日志', login: true},
                        alwaysShow: 0,
                        component: () => import ( "@/views/case/case-warning")
                    },
                ],
            },

        ]
        routers.forEach((e) => {
            router.addRoute(e)
        })

        store.commit('setMenuList', routers)
        resolve()
    })
}


// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
    return asyncRouterMap.filter(route => {
        if (type && route.children) {
            route.children = filterChildren(route.children)
        }
        if (route.component) {
            // Layout ParentView 组件特殊处理
            if (route.component === 'Layout') {
                route.component = Layout
            } else if (route.component === 'ParentView') {
                route.component = ParentView
            } else if (route.component === 'InnerLink') {
                route.component = InnerLink
            } else {
                route.component = loadView(route.component)
            }
        }
        if (route.children != null && route.children && route.children.length) {
            route.children = filterAsyncRouter(route.children, route, type)
        } else {
            delete route['children']
            delete route['redirect']
        }
        return true
    })
}

function filterChildren(childrenMap, lastRouter = false) {
    var children = []
    childrenMap.forEach((el, index) => {
        if (el.children && el.children.length) {
            if (el.component === 'ParentView' && !lastRouter) {
                el.children.forEach(c => {
                    c.path = el.path + '/' + c.path
                    if (c.children && c.children.length) {
                        children = children.concat(filterChildren(c.children, c))
                        return
                    }
                    children.push(c)
                })
                return
            }
        }
        if (lastRouter) {
            el.path = lastRouter.path + '/' + el.path
        }
        children = children.concat(el)
    })
    return children
}


export const loadView = (view) => {
    if (process.env.NODE_ENV === 'development') {
        return (resolve) => require([`@/views/${view}`], resolve)
    } else {
        // 使用 import 实现生产环境的路由懒加载
        // return () => import(`@/views/${view}`)
        // 解决webpack4 不支持变量方式的动态 import
        return (resolve) => require([`@/views/${view}`], resolve)
    }
}


export default dynamicRouters
