import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from "@/views/layout";
import store from "@/store";

Vue.use(VueRouter)

//公共路由
export const constantRoutes = [
    {
        path: '/',
        hidden: true,
        redirect: "/home",
    },
    {
        path: '/login',
        hidden: true,
        meta: {title: '欢迎登录', login: false},
        component: () => import('../views/login/login')
    },

    {
        path: '/home-detail',
        name: "home-detail",
        hidden: true,
        meta: {title: '案件详情', login: true},
        component: () => import('../views/layout/index'),
        children: [
            {
                status: 0,
                path: "/home-detail",
                name: "home-detail",
                meta: {title: "案件详情", icon: ""},
                component: () => import('../views/home/detail'),
            }
        ],
    },
    {
        path: '/detail',
        name: "detail",
        hidden: true,
        meta: {title: '案件详情', login: true},
        component: () => import('../views/layout/index'),
        children: [
            {
                status: 0,
                path: "/detail",
                name: "detail",
                meta: {title: "案件详情", icon: ""},
                component: () => import('../views/case/detail'),
            }
        ],
    },
    {
        path: '/managementDetail',
        name: "managementDetail",
        hidden: true,
        meta: {title: '考勤详情', login: true},
        component: () => import('../views/layout/index'),
        children: [
            {
                status: 0,
                path: "/managementDetail",
                name: "managementDetail",
                meta: {title: "考勤详情", icon: ""},
                component: () => import('../views/work/managementDetail'),
            }
        ],
    },
    {
        path: '/print',
        name: "print",
        hidden: true,
        meta: {title: '打印', login: false},
        component: () => import('../views/print/home-detail'),
    },
    {
        path: '/print-detail',
        name: "print_detail",
        hidden: true,
        meta: {title: '打印', login: false},
        component: () => import('../views/print/case-detail'),
    },
    {
        path: '/404',
        name: "404",
        hidden: true,
        meta: {title: '页面未找到', login: true},
        component: () => import('../views/layout/index'),
        children: [
            {
                status: 0,
                path: "/404",
                name: "404",
                meta: {title: "页面未找到", icon: ""},
                component: () => import('../views/404'),
            }
        ],
    },

]


// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = []

const createRouter = () => new VueRouter({
    mode: 'hash',
    scrollBehavior: () => ({y: 0}),
    base: process.env.BASE_URL,
    routes: constantRoutes
})
const router = createRouter()

//写一个重置路由的方法，切换用户后，或者退出时清除动态加载的路由
export function resetRouter() {
    console.log("重置路由")
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // 新路由实例matcer，赋值给旧路由实例的matcher，（相当于replaceRouter）
}

//全局前置守卫
router.beforeEach((to, from, next) => {
    //第一个参数to，包含的内容是切换后的路由对象，也就是跳转后的路由对象
    //第二个参数from，包含的内容的是切换前的路由对象，也就是跳转前的路由对象
    //第三个参数next()，是否往下执行，执行的话，如果不写的话路由就不会跳转，操作将会终止
    if (store.getters.getToken) {
        store.commit('setToken', store.getters.getToken)
    }

    if (!to.meta.login) return next()   //不需要验证登录
    return next()


})


export default router
