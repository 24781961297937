<template>
    <div id="app" v-loading.fullscreen.lock="$store.state.fullscreenLoading">
        <transition name="fade-transform" mode="out-in">
            <router-view v-wechat-title='$route.meta.title'/>
        </transition>
    </div>
</template>
<script>
import dynamicRouters from "@/router/getRouter";
import {getQueryVariable} from "@/utils";


export default {
    data() {
        return {
            get_notice_msg_time: null,
        }
    },
    created() {
        this.getOption()
        const url_token = getQueryVariable('token')
        if (url_token) this.$store.commit("setToken", url_token)

        if (this.$route.path !== '/' && this.$route.path !== '/login' && this.$route.path !== '/print-detail' && this.$route.path !== '/print') {
            if (!this.$store.state.token) this.openWin('/login')
        }

        if (this.$store.state.token) {
            this.getUserInfo()
            dynamicRouters()   //  如果以登录状态获取菜单
            this.getNoticeMsg()  //获取我的备忘预警

        }


        setTimeout(() => {
            this.$store.commit("setAppFirst", false)
        }, 5000)


    },

    methods: {

        getOption() {
            this.$api.post('/api/demo/config').then((res) => {
                const params = {
                    domain_jqr: res.data.domain_jqr,
                    domain_zdp: res.data.domain_zdp,
                }
                console.log("配置接口", params)
                this.$store.commit('saveOption', params)
            })
        },
    }
}

</script>

<style lang="scss">
@import "assets/font/iconfont.css";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.input:focus {
    outline: none;
}


.el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #2C3450 !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #2C3450 !important;
    color: #ffffff !important;
}

.dialog-view .el-dialog__footer {
    background: #ECF0F1;
    padding: 20px;

}

.upload-demo .el-upload {
    width: 100%;
    height: 100%;
}

.el-submenu__title {
    .item {
        border-radius: 10px;
        padding: 0 15px;
    }
}


.el-menu-item {
    background: #1f3279 !important;

    .item {
        border-radius: 10px;
        padding: 0 15px;
    }
}

.el-menu-item.is-active .item {
    background: #4F6DEA;
    border-radius: 10px;
    padding: 0 15px;

    .iconfont {
        color: #ffffff !important;
    }
}

.line-01 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /*两行*/
    overflow: hidden;
}

.line-02 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /*两行*/
    overflow: hidden;
}

.text-orange {
    color: #FF8813;
}

.text-red {
    color: #FD1E3D;
}

.text-green {
    color: #0CC295;
}

.el-menu-item-group__title {
    padding: 0px !important;
}


body::-webkit-scrollbar {
    position: absolute;
    /*滚动条整体样式*/
    width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
}

body::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background: #ffffff;
}

.el-table th.el-table__cell {
    background-color: #F8F9FC !important;
}

.page-height::-webkit-scrollbar {
    position: absolute;
    /*滚动条整体样式*/
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    background: rgb(0 0 0 / 50%);

}

.page-height::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background: #ffffff;
}

.el-aside::-webkit-scrollbar {
    position: absolute;
    /*滚动条整体样式*/
    width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
}

.el-aside::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background: #ffffff;
}

.ql-container {
    height: 350px !important;
}

.text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    /* max-width: 50%; */
}

.el-carousel__arrow--left {
    display: none !important;
}

.el-carousel__arrow--right {
    display: none !important;
}

.el-submenu .el-menu-item {
    padding: 0;
}

.el-menu-item {
    padding: 0 !important;
    padding-left: 20px !important;
}

.el-submenu .el-menu-item {
    min-width: auto !important;
}

.el-menu-item-group {
    .el-menu-item .item {
        padding-left: 40px;
    }
}

.html-content img {
    max-width: 100%;
}

.notice-detail .el-dialog {
    margin-top: 10vh !important;
}

.pagination-view {
    padding-top: 40px;
    display: flex;
    justify-content: center;
}


.table-btn-group {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin-top: 10px;
}


.el-table__body-wrapper {
    overflow: overlay;
}

.el-table__body-wrapper::-webkit-scrollbar {
    position: absolute;
    /*滚动条整体样式*/
    width: 18px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    background: #f8f9fc;

}

.el-table__body-wrapper::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 0px;
    background: rgb(0 0 0 / 40%);
}

.el-dialog__wrapper {
    overflow: overlay;
}

.el-dialog__wrapper::-webkit-scrollbar {
    position: absolute;
    /*滚动条整体样式*/
    width: 12px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    background: #f9fafd;

}

.el-dialog__wrapper::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background: #959698;
}


</style>
